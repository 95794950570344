/* loader.module.css */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw; /* Ensure it covers the full width */
  background-color: transparent;
}

.loadingText {
  font-size: 1.5rem;
  color: #ffffff; 
  /* font-weight: bold; */
  animation: blink 1.5s infinite; 
}

/* Keyframes for blinking effect */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
